.filter-button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  
  .filter-button, .active-button {
    padding: 1rem 3rem;
    color: $white;
    background-color: #373737;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 35px;
    min-width: 200px;
  }

  .active-button {
    background-color: transparent;
    border: 2px solid #373737;
    color: $theme-primary-color-s2;
  }
}


  

